.marker-position {
    position: relative;
    bottom: 33px;
    background-color: #FF5F1F;
    text-transform: capitalize;
    border-radius: 3px;
    padding: 2px 5px;
}

.simpleUserCardMainCont {
    width: 100%;
    overflow: hidden;
    padding: 3px 5px;
    border-bottom: 1px solid #ececec;
    position: relative;
    cursor: pointer;
}

.simpleUserCardMainCont .simpleUserCardType {
    position: absolute;
    top: -20px;
    right: 0px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: green;
    padding: 0px 3px;
    border-bottom-left-radius: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainCont:hover .simpleUserCardType {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: green;
    padding: 0px 3px;
    border-bottom-left-radius: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainCont .simpleUserCardRolesCont {
    width: 100%;
    height: 0px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainCont:hover .simpleUserCardRolesCont {
    width: 100%;
    height: 22px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 5px;
    transition: all .3s ease-in-out;
}













.simpleUserCardMainContDrawer {
    width: 100%;
    overflow: hidden;
    padding: 8px;
    border-bottom: 1px solid #ececec;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    margin-bottom: 10px;
}

.simpleUserCardMainContDrawer .simpleUserCardTypeDrawer {
    position: absolute;
    top: -20px;
    left: 0px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: green;
    padding: 0px 3px;
    border-bottom-right-radius: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainContDrawer:hover .simpleUserCardTypeDrawer {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: green;
    padding: 0px 3px;
    border-bottom-right-radius: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainContDrawer .simpleUserCardRolesContDrawer {
    width: 100%;
    height: 0px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainContDrawer:hover .simpleUserCardRolesContDrawer {
    width: 100%;
    height: 22px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 5px;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainContDrawer .closeBtn {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0;
    transition: all .3s ease-in-out;
}

.simpleUserCardMainContDrawer:hover .closeBtn {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 1;
    transition: all .3s ease-in-out;
}

.closeBtn .MuiSvgIcon-root {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.4);
}

.closeBtn .MuiIconButton-root {
    padding: 8px;
}






#react-doc-viewer {
    height: 100vh;
    width: calc(100% - 15px);
}

.simpleUserCardRolesCont>div::-webkit-scrollbar {
    display: none;
}

.simpleUserCardRolesCont>div {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#react-doc-viewer>div::-webkit-scrollbar {
    display: none;
}

#react-doc-viewer>div {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.style1 {
    width: 100%;
}

.style1 .ql-container {
    min-height: 10rem;
    max-height: 14rem;
    overflow-y: auto;
    border: none !important;
    font-size: .9rem;
}

.style1 .ql-toolbar {
    background-color: white;
}

.style1 .ql-editor {
    margin-left: 15px;
    padding: 0px;
    min-height: 10rem;
}

@media only screen and (max-width: 786px) {
    .style1 .ql-container {
        min-height: 5rem;
        max-height: 10rem;
    }

    .style1 .ql-editor {
        min-height: 5rem;
    }

    #react-doc-viewer {
        height: 70vh;
        width: 100%;
    }
}